import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of, tap} from 'rxjs';
import {ApiCountry} from 'src/app/auth_profile/model/casa/casa-model';
import {LessonType} from 'src/app/col/model/calendar';
import {ApiProduct} from 'src/app/col/model/products';
import {
  ApiCompetence,
  ApiCourse,
  ApiLearningUnitStudent,
  ApiLearningUnitTeacher,
  ApiLessonBundle,
  ApiLessonCommit,
  ApiLessonFlag,
  ApiLessonInstance,
  ApiLessonMessage,
  ApiLessonProgress,
  ApiPerson,
  ApiPersonalProfile,
  ApiPersonTechnicalProfile,
  ApiProductContext,
  ApiProductGift, ApiProvaContext,
  ApiStudentProductContext, ApiStudentSchedule,
  ApiTeacherProfile,
  ApiTeacherWorktime, ApiWorktimeDefinition,
  EntityRelatedRow,
  LessonBundleFilter,
  StudentCommitsFilter,
} from 'src/app/col/model/rest-model';
import {ItemAvailability} from 'src/app/cspa/model/cspa/personal';
import {Chapter, ExerciseSet} from 'src/app/cspa/model/cspa/struct';
import {Dates} from 'src/app/utils/calendar-utils';
import {Page, Pageable} from 'src/app/utils/pageable';
import {CacheService} from 'src/app/utils/services/cache.service';
import {environment} from 'src/environments/environment';
import {ColNativeServiceApi} from '../col-native-api';
import {StudentBookRestOldService} from "../../student/student-book-rest-old.service";
import {TeacherBookRestOldService} from "../../teacher/teacher-book-rest-old.service";
import {
  ApiLessonEventModel,
  LessonScheduleEventReference,
  ProductAvailabilityRequest,
  ScheduleReference, SimpleLessonScheduleRequest,
  SimpleProductAvailability,
  SimpleScheduleEvents, SimpleTeacherProductAvailability,
  StudentColLessonScheduleEvents,
  TeacherColLessonScheduleEvents
} from "../../../model/rest-model-v2";
import {Utils} from "../../../utils/lesson-utils";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class WebColNativeServiceApi implements ColNativeServiceApi {
  constructor(private http: HttpClient, private cache: CacheService,
              private studentBookRest: StudentBookRestOldService,
              private teacherBookRest: TeacherBookRestOldService) {}

  private getUrlLessonStudent(path: string, studentId?: number) {
    return (
      `${environment.lessonEndpoint}/student` +
       (studentId ? `/${studentId}` : '') +
       path
    );
  }
  private getUrlLessonTeacher(path: string, teacherId?: number) {
    return (
      `${environment.lessonEndpoint}/teacher` +
       (teacherId ? `/${teacherId}` : '') +
       path
    );
  }

  private getUrlCspa(path: string) {
    return `${environment.cspaEndpoint}/api${path}`;
  }

  private getUrlBookingStudentV2(studentId: number): string {
    return environment.bookingEndpoint + '/v2/students/' + studentId;
  }
  private getUrlBookingTeacherV2(teacherId: number): string {
    return environment.bookingEndpoint + '/v2/teacher/' + teacherId;
  }

  public cancelLesson(studentId: number, lessonId: number, reason: string) {
    return this.http.post<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonStudent(`/lessons/${lessonId}/cancel`, studentId)
      , reason)
  }

  public cancelLessonByTeacher(
    teacherId: number,
    lessonId: number,
    reason: string
  ): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    return this.http.patch<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/status/cancel`, teacherId),
      reason
    );
  }

  public commitLessonBooking(
    teacherId: number,
    lessonId: number
  ): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    return this.http.patch<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/status/due`, teacherId),
      {}
    );
  }

  public createSkypeClassroom(
    teacherId: number,
    lessonId: number
  ): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    return this.http.put<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/skype`, teacherId),
      {});
  }

  public createVideoClassroom(
    teacherId: number,
    lessonId: number
  ): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    return this.http.put<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/classroom`, teacherId),
      {});
  }

  public estimateNextProgress(
    teacherId: number,
    studentId: number,
    baseProgress: ApiLessonProgress,
    limit?: number
  ) {
    let params = new HttpParams();
    if (limit !== undefined) {
      params = params.append('limit', limit.toString());
    }

    return this.http.post<ApiLessonProgress[]>(
      this.getUrlLessonTeacher(`/students/${studentId}/progress/estimate`, teacherId),
      baseProgress,
      {
        params: params,
      }
    );
  }

  public findStudentProductGift(
    teacherId: number,
    studentId: number,
    courseCode: string
  ): Observable<ApiProductGift> {
    return this.http.get<ApiProductGift>(
      this.getUrlLessonTeacher(`/students/${studentId}/gifts/${courseCode}`, teacherId)
    );
  }

  public findUpcomingNextLesson(
    studentId: number
  ): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    return this.http.get<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonStudent('/lessons/upcoming/next', studentId)
    );
  }

  public findUpcomingNextLessonsMultiple(studentId: number): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>[]> {
    return this.http.get<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>[]>(
      this.getUrlLessonStudent('/lessons/upcoming/next-multiple', studentId)
    )
  }

  public finishLesson(
    teacherId: number,
    lessonId: number,
    progress: ApiLessonProgress,
    finishDate: Date
  ): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    let params = new HttpParams();
    if (finishDate) {
      params = params.append('finishDate', finishDate.toISOString());
    }
    return this.http.patch<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/status/finish`, teacherId),
      progress, {
        params: params,
      }
    );
  }

  getAvailabilities(
    path: string,
    depth: number
  ): Observable<ItemAvailability[]> {
    const params = new HttpParams()
      .append('path', path)
      .append('depth', depth.toString());
    return this.http.get<ItemAvailability[]>(
      this.getUrlCspa('/availability'), { params }
    );
  }

  getChapters(path: string, updatedAfter?: number): Observable<Chapter[]> {
    let params = new HttpParams();
    if (updatedAfter != null) {
      params = params.append('updatedAfter', updatedAfter.toString());
    }
    return this.http.get<Chapter[]>(
      this.getUrlCspa(`/sets/${path}`), {
      params,
    });
  }

  getCommits(
    studentId: number,
    filter: StudentCommitsFilter,
    pageable: Pageable
  ): Observable<Page<ApiLessonCommit>> {
    return this.http.get<Page<ApiLessonCommit>>(
      this.getUrlLessonStudent('/lessons/commits', studentId), {
      params: filter.apply(
        Pageable.appendPageableParams(new HttpParams(), pageable)
      ),
    });
  }

  getCourseAllowedCompetences(courseCode: string): Observable<ApiCompetence[]> {
    return this.http.get<ApiCompetence[]>(
      this.getUrlLessonStudent(`/courses/${encodeURIComponent(courseCode)}/competences`)
    );
  }

  getExerciseSets(): Observable<ExerciseSet[]> {
    return this.http.get<ExerciseSet[]>(this.getUrlCspa('/sets'));
  }

  public getLesson(
    studentId: number,
    lessonId: number
  ): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    return this.http.get<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonStudent(`/lessons/${lessonId}`, studentId)
    )
  }

  public getLessonById(
    teacherId: number,
    lessonId: number,
    studentId: number
  ): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    return this.http.get<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/students/${studentId}`, teacherId)
    )
  }

  getLessons(
    studentId: number,
    pageable: Pageable,
    lessonType: LessonType,
    isTeacher: boolean,
    productCode?: string
  ): Observable<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> {
    let url = isTeacher ? this.getUrlLessonTeacher('/lessons', studentId)
      : this.getUrlLessonStudent('/lessons', studentId)
    switch (lessonType) {
      case LessonType.Upcoming: {
        url += '/upcoming';
        break;
      }
      case LessonType.Incomplete: {
        url += '/incomplete';
        break;
      }
      case LessonType.Past: {
        url += '/past';
        break;
      }
    }
    let params = Pageable.appendPageableParams(new HttpParams(), pageable);
    if (productCode) params = params.append('productCode', productCode);
    return this.http
      .get<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>>(url, {
        params: params,
      })
  }

  getProductContext(
    studentId: number,
    productCode: string
  ): Observable<ApiProductContext> {
    return this.cache.cache(
      `col/student/${studentId}/progress/${productCode}/context`,
      () => this.http.get<ApiProductContext>(
        this.getUrlLessonStudent(`/progress/${productCode}/context`, studentId)
      ),
      60000
    );
  }

  public getProductCourses(productCode: string) {
    return this.http.get<ApiCourse[]>(this.getUrlLessonTeacher(`/product/${productCode}/courses`));
  }

  getProductProgressEstimation(
    studentId: number,
    productCode: string
  ): Observable<ApiLessonProgress[]> {
    return this.http.get<ApiLessonProgress[]>(
      this.getUrlLessonStudent(`/progress/${productCode}/promote-estimate`, studentId)
    );
  }

  getProductsList(lang: string, currency: string): Observable<ApiProduct[]> {
    const params = new HttpParams()
      .append('lang', lang)
      .append('currency', currency);
    return this.http.get<ApiProduct[]>(this.getUrlLessonStudent('/products'), {
      params: params
    });
  }

  public getProfile(teacherId: number): Observable<ApiTeacherProfile> {
    return this.http.get<ApiTeacherProfile>(this.getUrlLessonTeacher('/profile', teacherId));
  }

  getStudentLessonBundles(
    studentId: number,
    filter: LessonBundleFilter,
    pageable: Pageable
  ): Observable<Page<ApiLessonBundle>> {
    return this.cache.cache(
      `col/student/${studentId}/bundles/${filter.productCode}`,
      () =>
        this.http
          .get<Page<ApiLessonBundle>>(
            this.getUrlLessonStudent('/bundles', studentId), {
            params: filter.apply(
              Pageable.appendPageableParams(new HttpParams(), pageable)
            ),
          })
          .pipe(
            tap((bundlesPage) =>
              bundlesPage.content.forEach((bundle) => {
                bundle.date = Dates.parse(String(bundle.date));
                bundle.expiryDate = Dates.parse(String(bundle.expiryDate));
              })
            )
          ),
      60000
    );
  }

  public getStudentProductContext(
    teacherId: number,
    studentId: number,
    productCode: string
  ) {
    return this.http.get<ApiStudentProductContext>(
      this.getUrlLessonTeacher(`/students/${studentId}/product-context/${productCode}`, teacherId)
    );
  }

  public getStudentProgress(
    studentId: number,
    teacherId: number,
    isTeacher: boolean
  ): Observable<ApiLessonProgress[]> {
    const url = isTeacher
      ? this.getUrlLessonTeacher(`/students/${studentId}/progress`, teacherId)
      : this.getUrlLessonStudent('/progress', studentId);

    return this.http.get<ApiLessonProgress[]>(url);
  }

  public getStudentTechnicalProfileByTeacher(
    teacherId: number,
    studentId: number
  ): Observable<ApiPersonTechnicalProfile> {
    return this.http.get<ApiPersonTechnicalProfile>(
      this.getUrlLessonTeacher(`/students/${studentId}/person/technical-profile`, teacherId)
    );
  }

  public getStudentTechnicalProfile(
    studentId: number
  ): Observable<ApiPersonTechnicalProfile> {
    return this.http.get<ApiPersonTechnicalProfile>(
      this.getUrlLessonStudent(`/technical-profile`, studentId)
    );
  }

  public updateIntroductionState(studentId: number, state: string): Observable<ApiPersonTechnicalProfile> {
    return this.http.put<ApiPersonTechnicalProfile>(
      this.getUrlLessonStudent(`/technical-profile/introduction-state`, studentId), state
    )
  }

  getTeachers(
    studentId: number,
    teacherIds?: number[]
  ): Observable<ApiTeacherProfile[]> {
    let teacherIdsParam = new HttpParams();
    if (teacherIds?.length > 0) {
      teacherIds.forEach(
        (id) => (teacherIdsParam = teacherIdsParam.append('id', id.toString()))
      );
      return this.http.get<ApiTeacherProfile[]>(this.getUrlLessonStudent('/teachers', studentId), {
        params: teacherIdsParam,
      });
    }
    return this.cache.cache(
      `col/student/${studentId}/teachers`,
      () => this.http.get<ApiTeacherProfile[]>(this.getUrlLessonStudent('/teachers', studentId)),
      60000
    );
  }

  getLessonScheduleEventReference(
    studentId: number,
    scheduleId: number
  ): Observable<LessonScheduleEventReference<ApiLessonEventModel>> {
    const url = this.getUrlBookingStudentV2(studentId) + `/schedule/${scheduleId}/event`;
    return this.http.get<LessonScheduleEventReference<ApiLessonEventModel>>(url)
  }

  listTeachersWeekAvailability(studentId: number, focusDate: Date, productCode: string): Observable<ApiTeacherWorktime[]> {
    return this.studentBookRest.listTeachersWeekAvailability(studentId, focusDate, productCode)
  }

  listStudentWeekSchedules(studentId: number, focusDate: Date): Observable<ApiStudentSchedule[]> {
    return this.studentBookRest.listStudentWeekSchedules(studentId, focusDate)
  }
  reserveSchedule(studentId: number, schedule: ApiStudentSchedule): Observable<ApiStudentSchedule> {
    return this.studentBookRest.reserveSchedule(studentId, schedule)
  }

  public getCountries(locale?: string): Observable<ApiCountry[]> {
    const url = environment.lessonEndpoint + '/public/countries';

    let params = new HttpParams();
    if (locale) {
      params = params.append('locale', locale);
    }

    return this.http.get<ApiCountry[]>(url, { params: params }).pipe();
  }

  public giveStudentProductGift(
    teacherId: number,
    studentId: number,
    courseCode: string
  ): Observable<void> {
    return this.http.post<void>(
      this.getUrlLessonTeacher(`/students/${studentId}/gifts/${courseCode}`, teacherId),
      {}
    );
  }

  public listLessonHistory(
    teacherId: number,
    lessonId: number,
    studentId: number,
    pageable: Pageable
  ): Observable<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>> {
    return this.http.get<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/students/${studentId}/history`, teacherId), {
      params: Pageable.appendPageableParams(new HttpParams(), pageable),
    });
  }

  public listAvailabilities(
    teacherId: number,
    focusDate: Date
  ): Observable<SimpleProductAvailability[]> {
    let weekStart = Utils.getWeekStartDate(focusDate)
    const url = this.getUrlBookingTeacherV2(teacherId) + '/availabilities/events';
    return this.http.get<SimpleProductAvailability[]>(url, {
      params: {
        dateFrom: weekStart.toISOString(),
        dateTo: new Date(weekStart.getTime() + 7 * 24 * 60 * 60 * 1000).toISOString(),
      }
    }).pipe(
      tap(schedules => {
        schedules.forEach( schedule => schedule.events.forEach(ev => ev.eventDate = Dates.parse(String(ev.eventDate))))
      }),
      map(worktimes => worktimes.map( wt => Object.assign(new SimpleProductAvailability(), wt)))
    );
  }

  public listLessons(teacherId: number, focusDate: Date): Observable<TeacherColLessonScheduleEvents[]> {
    let weekStart = Utils.getWeekStartDate(focusDate)
    const url = this.getUrlBookingTeacherV2(teacherId) + '/lessons';

    return this.http.get<TeacherColLessonScheduleEvents[]>(url, {
      params: {
        dateFrom: weekStart.toISOString(),
        dateTo: new Date(weekStart.getTime() + 7 * 24 * 60 * 60 * 1000).toISOString(),
      }
    }).pipe(
      tap( schedules => {
        schedules.forEach( schedule => schedule.events.forEach(ev => ev.eventDate = Dates.parse(String(ev.eventDate))))
      })
    );
  }

  public deleteAvailability(
    teacherId: number,
    availabilityId: number,
    eventId: number,
    applyRecurring: boolean
  ): Observable<void> {
    const url = this.getUrlBookingTeacherV2(teacherId) + `/availabilities/${availabilityId}/events/${eventId}`;

    return this.http.delete<void>(url, {
      params: {
        'applyFuture': applyRecurring.toString()
      }
    });  }

  public createAvailability(
    teacherId: number,
    request: ProductAvailabilityRequest
  ): Observable<ScheduleReference> {
    const url = this.getUrlBookingTeacherV2(teacherId) + '/availabilities';
    return this.http.post<ScheduleReference>(url, request)
  }

  listLessonTypesReportForTeacher(
    teacherId: number,
    dateFrom: Date,
    dateTo: Date,
    productCode: string
  ) {
    const url =
      environment.lessonEndpoint +
      '/reports/teacher/' +
      teacherId +
      '/lesson-types';

    let params = new HttpParams();
    if (dateFrom) {
      params = params.append('dateFrom', dateFrom.toISOString());
    }
    if (dateTo) {
      params = params.append('dateTo', dateTo.toISOString());
    }
    if (productCode) {
      params = params.append('productCode', productCode);
    }

    return this.http.get<EntityRelatedRow<ApiTeacherProfile>[]>(url, {
      params: params,
    });
  }

  public notifyStudentAboutLessonStart(teacherId: number, lessonId: number) {
    return this.http.post<void>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/notifications/lesson-start`, teacherId),
      {}
    );
  }

  public postLessonComment(
    teacherId: number,
    lessonId: number,
    message: ApiLessonMessage
  ): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    return this.http.post<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/messages`, teacherId),
      message);
  }

  public postLessonMessageForStudent(
    teacherId: number,
    lessonId: number,
    studentId: number,
    message: string
  ): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    return this.http.post<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/students/${studentId}/messages`, teacherId),
      message);
  }

  public queryForStudentsById(
    teacherId: number,
    studentIds: number[]
  ): Observable<Page<ApiLearningUnitStudent<ApiPerson<ApiPersonalProfile>>>> {
    if (!studentIds || studentIds.length === 0) {
      return of(Page.empty<null>());
    }

    let params = new HttpParams();
    studentIds.forEach((id) => (params = params.append('id', id.toString())));

    return this.http.get<Page<ApiLearningUnitStudent<ApiPerson<ApiPersonalProfile>>>>(
      this.getUrlLessonTeacher('/students', teacherId),
       {
      params: params,
    });
  }

  public registerLessonFlag(
    teacherId: number,
    lessonId: number,
    lessonFlag: ApiLessonFlag
  ): Observable<ApiLessonFlag> {
    return this.http.post<ApiLessonFlag>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/flags`, teacherId),
      lessonFlag);
  }

  public saveStudentProductContext(
    teacherId: number,
    studentId: number,
    productCode: string,
    context: ApiProductContext
  ) {
    return this.http.put<ApiProductContext>(
      this.getUrlLessonTeacher(`/students/${studentId}/product-context/${productCode}`, teacherId),
      context);
  }

  public squanderLesson(studentId: number, lessonId: number, reason: string) {
    return this.http.post<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonStudent(`/lessons/${lessonId}/squander`, studentId), reason);
  }

  public squanderLessonByTeacher(
    teacherId: number,
    lessonId: number,
    reason: string
  ): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    return this.http.patch<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/status/squander`, teacherId),
      reason);
  }

  public startLesson(
    teacherId: number,
    lessonId: number,
    startDate: Date
  ): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    let params = new HttpParams();
    if (startDate) {
      params = params.append('startDate', startDate.toISOString());
    }
    return this.http.patch<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/status/start`, teacherId),
      {}, { params: params });
  }

  public updateLessonProgress(
    teacherId: number,
    lessonId: number,
    progress: ApiLessonProgress
  ): Observable<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>> {
    return this.http.put<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/progress`, teacherId),
      progress);
  }

  public updateLessonType(
    teacherId: number,
    lessonId: number,
    lessonType: string
  ) {
    return this.http.put<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>(
      this.getUrlLessonTeacher(`/lessons/${lessonId}/type`, teacherId),
      lessonType);
  }
  public updateProductVersion(teacherId: number, studentId: number, productCode: string, version: string) {
    return this.http.patch<ApiStudentProductContext>(
      `${environment.lessonEndpoint}/teacher/${teacherId}/students/${studentId}/product-context/${productCode}/product-version`,
      version
    );
  }

  createWorktime(teacherId: number, definition: ApiWorktimeDefinition): Observable<ApiWorktimeDefinition> {
    return this.teacherBookRest.createWorktime(teacherId, definition);
  }

  deleteWorktime(teacherId: number, worktimeId: number, applyRecurring: boolean): Observable<void> {
    return this.teacherBookRest.deleteWorktime(teacherId, worktimeId, applyRecurring)
  }

  listWeekSchedules(teacherId: number, focusDate: Date): Observable<ApiStudentSchedule[]> {
    return this.teacherBookRest.listWeekSchedules(teacherId, focusDate)
  }

  listWorktimeWeekSchedules(teacherId: number, focusDate: Date): Observable<ApiTeacherWorktime[]> {
   return this.teacherBookRest.listWorktimeWeekSchedules(teacherId, focusDate)
  }

  getProvaContext(studentId: number, productCode?: string): Observable<ApiProvaContext[]> {
    let params = new HttpParams();
    if (productCode) {
      params = params.append('productCode', productCode);
    }
    return this.http.get<ApiProvaContext[]>(
      this.getUrlLessonStudent(`/prova-context`, studentId), {params: params});
  }

  addStudentProductContext(studentId: number, productCode: string): Observable<ApiProductContext> {
    const url = this.getUrlLessonStudent('/progress/' + productCode + '/context', studentId)

    return this.cache.updateCache(
      `col/student/${studentId}/progress/${productCode}/context`,
      () => this.http.post<ApiProductContext>(url, {}));
  }

  chargeFreeCredit(studentId: number, productCode: string): Observable<ApiLessonBundle> {
    const url = this.getUrlLessonStudent('/bundle/' + productCode + '/free', studentId);
    this.cache.removeCacheEntry(`col/student/${studentId}/bundles/${productCode}`)
    return this.http.post<ApiLessonBundle>(url, {});
  }

  listStudentLessonSchedules(studentId: number, focusDate: Date, productCode: string): Observable<StudentColLessonScheduleEvents[]> {
    let weekStart = Utils.getWeekStartDate(focusDate)
    const url = this.getUrlBookingStudentV2(studentId) + '/schedules';

    return this.http.get<StudentColLessonScheduleEvents[]>(url, {
      params: {
        dateFrom: weekStart.toISOString(),
        dateTo: new Date(weekStart.getTime() + 7 * 24 * 60 * 60 * 1000).toISOString(),
        productCode: productCode,
        type: "lesson"
      }
    }).pipe(
      tap( schedules => {
        schedules.forEach( schedule => schedule.events.forEach(ev => ev.eventDate = Dates.parse(String(ev.eventDate))))
      })
    );
  }

  listStudentSchoolTeachersAvailability(studentId: number, focusDate: Date, productCode: string): Observable<SimpleTeacherProductAvailability[]> {
    let weekStart = Utils.getWeekStartDate(focusDate)

    const url = this.getUrlBookingStudentV2(studentId) + '/teacher-availability';
    return this.http.get<SimpleTeacherProductAvailability[]>(url, {
      params: {
        dateFrom: weekStart.toISOString(),
        dateTo: new Date(weekStart.getTime() + 7 * 24 * 60 * 60 * 1000).toISOString(),
        productCode: productCode
      }
    }).pipe(
      tap( schedules => {
        schedules.forEach( schedule => schedule.events.forEach(ev => ev.eventDate = Dates.parse(String(ev.eventDate))))
      }),
      map(worktimes => worktimes
          .filter(schedule => schedule.events.some(e => (e.eventDate.getTime() + e.duration) >= focusDate.getTime()))
          .map(worktime => Object.assign(new SimpleTeacherProductAvailability(), worktime))
      )
    );
  }

  reserveScheduleV2(studentId: number, request: SimpleLessonScheduleRequest): Observable<StudentColLessonScheduleEvents> {
    const url = this.getUrlBookingStudentV2(studentId) + '/schedules';
    return this.http.post<StudentColLessonScheduleEvents>(url, request, {
      params: {
        type: "col_lesson"
      }
    }).pipe(
      tap( schedule => schedule.events.forEach(ev => ev.eventDate = Dates.parse(String(ev.eventDate)))
      )
    )
  }
}
