import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductContextService {

  productContext = new Subject<void>()

  constructor() { }

  productContextUpdated(){
    this.productContext.next()
  }
}
