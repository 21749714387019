<app-week-calendar
  [showFilter]="true"
  [loading]="loading"
  [events]="events"
  (dateUpdate)="focusDate = $event"
  (timeSelect)="onCalendarClick($event)"
  (openFilterModal)="openFilterModal.emit()"
></app-week-calendar>
<app-activate-course-modal
  #activateCourseModalEvent
  [studentId]="_studentId"
  [langCode]="_langCode"
  [defaultContent]="false"
  [isHidden]="true"
>
  <h5 class="modal-title" header>
    <p class="small mb-0">
      {{ "COL.COURSES.CALENDAR.MODAL.SCHEDULE.RESERVE_ON" | translate }}
    </p>
    <p class="mb-0">
      {{ _slScheduleDate | dateTranslation : "EEEE, dd MMM yyyy, HH:mm" }}
    </p>
  </h5>
  <span body>
    {{"COL.ACTIVATE.INFO" | translate}}
  </span>
</app-activate-course-modal>
<app-modal #noCreditsModal>
  <div class="modal-content">
    <div class="modal-header border-0">
      <h5>{{ "COL.COURSES.CALENDAR.MODAL.NO_CREDITS.TITLE" | translate }}</h5>
      <button
        type="button"
        class="btn-close"
        (click)="noCreditsModal.hide()"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body">
      {{ "COL.COURSES.CALENDAR.MODAL.NO_CREDITS.BODY" | translate }}
    </div>
    <div class="modal-footer border-0">
      <button
        type="button"
        class="btn btn-secondary rounded-pill"
        (click)="noCreditsModal.hide()"
      >
        {{ "COL.COURSES.CALENDAR.MODAL.NO_CREDITS.NOT_NOW" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-primary rounded-pill"
        (click)="noCreditsModal.hide()"
        routerLink="/student/plans"
        [queryParams]="{language: _langCode}"
      >
        {{ "BUTTON.BUY_PLAN" | translate }}
      </button>
    </div>
  </div>
</app-modal>
<app-modal #reserveErrorModal>
  <div class="modal-content">
    <div class="modal-header border-0">
      <span>{{ "COL.COURSES.CALENDAR.MODAL.ERROR.TITLE" | translate }}</span>
      <button
        type="button"
        class="btn-close"
        (click)="reserveErrorModal.hide()"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body">
      <p>{{ _errorMessage }}</p>
    </div>
    <div class="modal-footer border-0">
      <button
        type="submit"
        (click)="reserveErrorModal.hide()"
        class="btn btn-primary rounded-pill"
      >
        {{ "BUTTON.OK" | translate }}
      </button>
    </div>
  </div>
</app-modal>
<app-modal #scheduleLessonModal >
  <div class="modal-content top-0">
    <div class="modal-header border-0">
      <h5 class="modal-title">
        <p class="small mb-0">
          {{ "COL.COURSES.CALENDAR.MODAL.SCHEDULE.RESERVE_ON" | translate }}
        </p>
        <p class="mb-0">
          {{ _slScheduleDate | dateTranslation : "EEEE, dd MMM yyyy, HH:mm" }}
        </p>
      </h5>
      <button
        type="button"
        class="btn-close"
        (click)="scheduleLessonModal.hide()"
        aria-label="Close"
      ></button>
    </div>

    <form #f="ngForm" (ngSubmit)="scheduleLesson()" class="modal-body">
        <div class="form-group" *ngIf="!_slShowTeachers">
          <p>
            <span>
              {{
                "COL.COURSES.CALENDAR.MODAL.SCHEDULE.TEACHER_AUTO_SELECT"
                  | translate
              }}</span
            >
            <a tabindex (click)="_slShowTeachers = true">{{
              "COL.COURSES.CALENDAR.MODAL.SCHEDULE.MANUAL_LINK" | translate
            }}</a
            >.
          </p>
        </div>
        <div *ngIf="_slShowTeachers">
          <p *ngIf="_slTeacherIds.length !== 1">
            {{
              "COL.COURSES.CALENDAR.MODAL.SCHEDULE.SELECT_TEACHER" | translate
            }}
          </p>
          <div>
            <div class="list-group teachers">
              <a
                tabindex=""
                *ngFor="let teacherId of _slTeacherIds; let i = index"
                (click)="_slPreferredTeacher = teacherId"
                class="list-group-item list-group-item-action d-flex p-2"
                [ngClass]="{ active: teacherId === _slPreferredTeacher }"
              >
                <div
                  *ngIf="getTeacherPhoto(teacherId)"
                  class="profile-photo rounded-circle me-3 initials-circle"
                  [style.background-image]="
                    'url(' + getTeacherPhoto(teacherId) + ')'
                  "
                ></div>
                <div
                  *ngIf="!getTeacherPhoto(teacherId)"
                  class="d-flex rounded-circle text-center bg-gray me-3 initials-circle"
                >
                  <span class="text-muted m-auto">
                    {{ getInitials(teacherId) }}
                  </span>
                </div>
                <div class="my-auto">
                  <h6 class="mb-0">{{ getTeacherName(teacherId) }}</h6>
                  <div>{{ getTeacherDescription(teacherId) }}</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      <div class="modal-footer border-0 px-0 pb-0">
        <button
          type="submit"
          [disabled]="!_slPreferredTeacher"
          class="btn btn-primary rounded-pill"
        >
          {{ "BUTTON.RESERVE_LESSON" | translate }}
        </button>
      </div>
    </form>
  </div>
</app-modal>
