import {Component, Input, ViewChild} from '@angular/core';
import {of, switchMap, tap} from "rxjs";
import { CourseFilterComponent } from 'src/app/utils/course-filter/course-filter.component';
import {StudentRestServiceImpl} from "../../col/services/student/student-rest-impl.service";
import {ModalComponent} from "../modal/modal.component";
import {ApiProductContext} from "../../col/model/rest-model";
import { ProductContextService } from 'src/app/col/services/product-context.service';

@Component({
  selector: 'app-activate-course-modal',
  templateUrl: './activate-course-modal.component.html',
  styleUrls: ['./activate-course-modal.component.scss']
})
export class ActivateCourseModalComponent {
  private _studentId: number;
  private _lang: string;
  isActivatingInProgress = false
  CourseFilterComponent = CourseFilterComponent
  private productContext: ApiProductContext = new ApiProductContext()

  @Input() defaultContent = true
  @Input() onlyButton = false
  @Input() isHidden = false
  @Input()
  set studentId(value: number) {
    this._studentId = value;
    this.getProductContext().subscribe()
  }
  get studentId() {
    return this._studentId;
  }
  @Input()
  public set langCode(lang: string) {
    this._lang = lang;
    this.getProductContext().subscribe()
  }
  public get langCode() {
    return this._lang
  }
  @Input()
  set contextUpdate(context) {
    this.productContext = context
  }
  @ViewChild("activateCourseModal", {static: true}) activateCourseModal: ModalComponent

  constructor(private studentRest: StudentRestServiceImpl, 
              private productContextService: ProductContextService) {}

  activateCourse() {
    this.isActivatingInProgress = true
    this.studentRest.addStudentProductContext(this.studentId, this.langCode).pipe(
      tap((response) => this.productContext = response),
      switchMap(_ => this.studentRest.chargeFreeCredit(this.studentId, this.langCode)),
    ).subscribe(_ => {
      this.isActivatingInProgress = false
      this.productContextService.productContextUpdated()
      this.activateCourseModal.hide()
    })
  }

  getLanguageTranslation(langCode: string) {
    if(!langCode) return ''
    let lang = CourseFilterComponent.getFiltersShort().find(l => l.filter == langCode)
    return "EBOOKS.FILTER_LANGS." + lang.translationCode
  }

  getProductContext() {
    if (!this.studentId || !this.langCode)
      return of(null);

    return this.studentRest.getProductContext(this.studentId, this.langCode).pipe(
      tap(productContext => this.productContext = productContext)
    )
  }

  isCourseActive() {
    return !!this.productContext
  }
}
