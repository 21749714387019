<div class="card mb-5" *ngIf="!isLoading && isDisplayed()">
  <div class="card-header border-0">
    <div class="btn-group float-end" role="group" *ngIf="hasAnyProgress()">
      <button
        [disabled]="!hasPrev()"
        (click)="moveCourse(-1)"
        class="btn btn-outline-secondary rounded-start-3"
      >
        <app-icon name="angle-left" class="icon-blue lg-icon"></app-icon>
      </button>
      <button class="btn btn-outline-secondary text-white pe-none">
        {{ getCurrentCourseName() }}
      </button>
      <button
        [disabled]="!hasNext()"
        (click)="moveCourse(1)"
        class="btn btn-outline-secondary rounded-end-3"
      >
        <app-icon name="angle-right" class="icon-blue lg-icon"></app-icon>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="p-2" *ngIf="!hasAnyProgress()">
      {{ "COL.COURSES.PROGRESS.MISSING_PROGRESS_EXPLANATION" | translate }}
    </div>

    <div *ngIf="hasAnyProgress()" class="row">
      <div
        class="col-12 col-md-6 border-md-right border-secondary d-flex flex-column justify-content-between mb-4 mb-md-0 px-lg-4"
      >
        <div class="row mb-3">
          <div class="col">
            {{ "COL.COURSES.PROGRESS.NEW_WORK" | translate }}
          </div>
          <div class="col d-flex justify-content-around">
            <div class="progress my-2 w-75 me-3 d-md-none d-lg-flex">
              <div
                class="progress-bar bg-success"
                role="progressbar"
                [ngStyle]="getLessonProgressWidth()"
              ></div>
            </div>
            <span class="text-success progress-text text-center"
              >{{ getProgress() | number : "1.0-0" }}%</span
            >
          </div>
        </div>

        <div class="row mb-3">
          <div class="col">
            <span>
              {{ "COL.COURSES.PROGRESS.EXAM" | translate }}
            </span>
          </div>
          <div class="col d-flex justify-content-around">
            <div class="progress my-2 w-75 me-3 d-md-none d-lg-flex">
              <div
                class="progress-bar bg-warning"
                role="progressbar"
                [ngStyle]="getExamProgressWidth()"
              ></div>
            </div>
            <span class="text-warning progress-text text-center">{{
              getExamProgressDescription()
            }}</span>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col">
            <span>
              {{ "COL.COURSES.PROGRESS.FULL_REVISION" | translate }}
            </span>
          </div>
          <div class="col d-flex justify-content-around">
            <div class="progress my-2 w-75 me-3 d-md-none d-lg-flex">
              <div
                class="progress-bar bg-info"
                role="progressbar"
                [ngStyle]="getRevisionProgressWidth()"
              ></div>
            </div>
            <span class="text-info progress-text text-center"
              >{{ getRevisionProgress() | number : "1.0-0" }}%</span
            >
          </div>
        </div>
      </div>

      <!-- lesson dates -->
      <div class="card-text col-12 col-md-6 px-lg-4">
        <div *ngIf="hasAnyLesson() && getFirstLessonDate()">
          <div class="row">
            <div class="col-6">
              <p class="text-muted mb-0 small">
                {{ "COL.COURSES.PROGRESS.START_DATE" | translate }}
              </p>
              <p>{{ getFirstLessonDate() | date : "dd/MM/yy" }}</p>
            </div>
            <div class="col-6">
              <p class="text-muted mb-0 small">
                {{ "COL.COURSES.PROGRESS.END_DATE" | translate }}
              </p>
              <p>{{ getLastLessonDate() | date : "dd/MM/yy" }}</p>
            </div>
            <div class="col-6">
              <p class="text-muted mb-0 small">
                {{ "COL.COURSES.PROGRESS.LESSONS_TAKEN" | translate }}
              </p>
              <p>{{ getNumberOverallLessons() }}</p>
            </div>
            <div class="col-6">
              <p class="text-muted mb-0 small">
                {{ "COL.COURSES.PROGRESS.LAST_EXAM_DATE" | translate }}
              </p>
              <p *ngIf="getLastExamDate()">
                {{ getLastExamDate() | date : "dd/MM/yy" }}
              </p>
              <p *ngIf="!getLastExamDate()">---</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer border-0 text-end px-4 pb-3">
    <button
      *ngIf="isCourseActive()"
      class="btn btn-primary rounded-pill btn-bubble"
      routerLink="/student/reserve-lesson"
      [queryParams]="{language: langCode}"
    >
      {{ "BUTTON.BOOK_LESSON" | translate }}
    </button>
    <app-activate-course-modal
      [studentId]="studentId"
      [langCode]="langCode"
      [onlyButton]="true"
    ></app-activate-course-modal>
  </div>
</div>
