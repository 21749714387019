import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild,} from '@angular/core';
import {finalize, map, Observable, of, Subscription, switchMap, tap, zip,} from 'rxjs';
import {PersonUtils} from 'src/app/auth_profile/utils/person-utils';
import {
  ApiCompetence,
  ApiCourse,
  ApiCourseProduct,
  ApiLessonBundle,
  ApiLessonProgress,
  ApiLessonType,
  ApiPersonalProfile,
  ApiProductContext,
  ApiTeacherProfile,
} from 'src/app/col/model/rest-model';
import { AppEventsService } from 'src/app/col/services/app-events.service';
import { StudentRestServiceImpl } from 'src/app/col/services/student/student-rest-impl.service';
import { ErrorBase } from 'src/app/model/ErrorBase';
import { Dates } from 'src/app/utils/calendar-utils';
import { ModalComponent } from 'src/app/utils/modal/modal.component';
import { Page, Pageable } from 'src/app/utils/pageable';
import {CalendarEntry, ClickEvent, DateRange, DateRangeSet} from '../../../model/calendar';
import {
  LocalDateTime,
  SimpleLessonScheduleRequest,
  SimpleScheduleEvents,
  SimpleTeacherProductAvailability,
  TeacherReference
} from "../../../model/rest-model-v2";
import {ActivateCourseModalComponent} from "../../../../utils/activate-course-modal/activate-course-modal.component";
import { Router } from '@angular/router';
import { ProductContextService } from 'src/app/col/services/product-context.service';

@Component({
  selector: 'app-student-main-calendar',
  templateUrl: './student-main-calendar.component.html',
  styleUrls: ['./student-main-calendar.component.scss'],
})
export class StudentMainCalendarComponent implements OnInit, OnDestroy {
  @ViewChild('scheduleLessonModal', { static: true })
  scheduleLessonModal: ModalComponent;
  @ViewChild('noCreditsModal', { static: true })
  noCreditsModal: ModalComponent;
  @ViewChild('reserveErrorModal', { static: true })
  reserveErrorModal: ModalComponent;
  @ViewChild('activateCourseModalEvent', { static: true })
  activateCourseModalEvent: ActivateCourseModalComponent;
  @Output() scheduleDateEmitter = new EventEmitter<Date>()
  @Output() openFilterModal = new EventEmitter();

  _studentId: number;
  _langCode: string;
  private teachersIdFilter: number[];
  private _focusDate: Date;

  events: CalendarEntry[];
  availableWorktimes: SimpleTeacherProductAvailability[] = [];
  schedules: SimpleScheduleEvents[];
  requiredCompetences: string[];
  loading: boolean = true;
  studentProductContext: ApiProductContext;
  credits = 0;
  creditsSubscription: Subscription;
  // lesson schedule variables
  _slScheduleDate: Date;
  _slTeacherIds: number[];
  _slTeacherProfiles: { [id: number]: ApiTeacherProfile };
  _slShowTeachers = false;
  _slPreferredTeacher: number = null;
  _slScheduleFinishDate: Date;
  _slSelectedWorktimes: SimpleTeacherProductAvailability[];
  _slShowAll = true;

  _errorMessage: string;

  productContextSubscription: Subscription

  @Input()
  set filterTeachersIds(teachersIds: number[]) {
    this.teachersIdFilter = teachersIds;
    this.remapCalendarEntries();
  }

  @Input()
  set studentId(studentId: number) {
    this._studentId = studentId;
    this.loadCalendarEvents();
    this.getProductContext().subscribe()
  }

  @Input()
  set langCode(langCode: string) {
    if(langCode == '') {
      this.loading = false
      return
    }
    this._langCode = langCode;
    this.loadCalendarEvents();
    this.subscribeForCredits();
    this.getCredits().subscribe()
    this.getProductContext().subscribe()
  }

  set focusDate(date: Date) {
    this._focusDate = date;
    this.loadCalendarEvents();
    this.getTeachersWeekAvailability().subscribe()
  }

  @Output()
  availableTeachersIdsUpdate = new EventEmitter<number[]>();

  constructor(
    private studentRest: StudentRestServiceImpl,
    private appEvents: AppEventsService,
    private router: Router,
    private productContextService: ProductContextService
  ) {}

  ngOnInit(): void {
    this.productContextSubscription = this.productContextService.productContext.pipe(
      switchMap(_ => this.getProductContext()),
      switchMap(context => this.getCourseCode(context)),
      switchMap(_ => this.getCredits())
    ).subscribe()
  }

  ngOnDestroy(): void {
    this.productContextSubscription.unsubscribe()
  }

  getCredits(): Observable<Page<ApiLessonBundle>> {
    return this.studentRest
      .getStudentLessonBundles(
        this._studentId,
        this._langCode,
        Pageable.of(0, 1000, null)
      )
      .pipe(
        tap((result) => {
          this.credits = 0;
          result.content.forEach(
            (bundle) => (this.credits += bundle.available)
          );
          this.appEvents.credits(this._langCode).next(this.credits);
        })
      );
  }

  subscribeForCredits(): any {
    if (!this._langCode || this.creditsSubscription) {
      return;
    }
    this.creditsSubscription = this.appEvents
      .credits(this._langCode)
      .subscribe((credits) => (this.credits = credits));
  }

  loadCalendarEvents() {
    if (!this._studentId || !this._langCode || !this._focusDate) {
      return;
    }
    let loadProductContext: Observable<null | ApiCompetence[]> = of(null);
    this.loading = true;
    if (!this.requiredCompetences) {
      loadProductContext = this.getProductContext().pipe(
        switchMap((context) => this.getCourseCode(context)),
        switchMap((code) => this.getCompetences(code)),
        tap(
          (competences) =>
            (this.requiredCompetences = competences.map(
              (competence) => competence.code
            ))
        )
      );
    }

    loadProductContext
      .pipe(
        switchMap(() =>
          zip(
            this.getTeachersWeekAvailability(),
            this.getStudentWeekSchedules()
          )
        ),
        tap(() => this.remapCalendarEntries()),
        finalize(() => (this.loading = false))
      )
      .subscribe();
  }

  getProductContext(): Observable<ApiProductContext> {
    if (!this._studentId || !this._langCode)
      return of(null)

    return this.studentRest
      .getProductContext(this._studentId, this._langCode)
      .pipe(
        map((context) => (context ? context : new ApiProductContext())),
        tap(
          (context: ApiProductContext) =>
            (this.studentProductContext = context)
        )
      );
  }

  getCourseCode(context: ApiProductContext): Observable<string> {
    if(!context)
      return of(null)
    if (context.currentCourse) {
      return of(context.currentCourse.code);
    }
    return this.studentRest
      .getProductProgressEstimation(this._studentId, this._langCode)
      .pipe(
        tap((p) => {
          this.studentProductContext.currentCourse = new ApiCourse();
          this.studentProductContext.currentCourse.code = p[0]?.courseCode;
        }),
        map((p: ApiLessonProgress[]) =>
          p.length > 0 && p[0] ? p[0].courseCode : null
        )
      );
  }

  getCompetences(courseCode: string): Observable<ApiCompetence[]> {
    const competencesFromLessonType =
      this.mapContextLessonTypeOnRequiredCompetences();
    if (competencesFromLessonType) {
      return of(competencesFromLessonType);
    }
    return this.studentRest.getCourseAllowedCompetences(courseCode);
  }

  getTeachersWeekAvailability(): Observable<SimpleTeacherProductAvailability[]> {
    return this.studentRest
      .listStudentSchoolTeachersAvailability(
        this._studentId,
        this._focusDate,
        this._langCode
      )
      .pipe(
        map((worktimes: SimpleTeacherProductAvailability[]) =>
          worktimes.filter((wt) => {
            const wtCompetences = wt.details.competences.map(c => c.code );
            return wt.details.product.code === this._langCode && wtCompetences.some( c => this.requiredCompetences?.includes(c));
          })
        ),
        tap((worktimes: SimpleTeacherProductAvailability[]) => {
          this.collectAvailableTeacherIds(worktimes);
          this.availableWorktimes = worktimes;
        })
      );
  }

  getStudentWeekSchedules(): Observable<SimpleScheduleEvents[]> {
    return this.studentRest
      .listStudentLessonSchedules(this._studentId, this._focusDate, this._langCode)
      .pipe(tap((schedules) => (this.schedules = schedules)));
  }

  remapCalendarEntries() {
    let schedulesEvents: CalendarEntry[] = [];
    if (this.schedules) {
      schedulesEvents = this.schedules.map((schedule) =>
        this.mapScheduleToCalendarEntry(schedule)
      );
    }

    let worktimes = [];
    if (this.availableWorktimes) {
      worktimes = this.availableWorktimes;
    }
    if (this.teachersIdFilter && this.teachersIdFilter.length > 0) {
      worktimes = worktimes.filter(
        (wt) => this.teachersIdFilter.indexOf(wt.teacher.id) >= 0
      );
    }
    const availabilities = this.mapAvailabilitiesToCalendarEntries(worktimes);

    this.events = availabilities.concat(schedulesEvents);
  }

  collectAvailableTeacherIds(worktimes: SimpleTeacherProductAvailability[]): void {
    const availableTeacherIds = Array.from(
      new Set(worktimes.map((wt) => wt.teacher.id))
    );
    this.availableTeachersIdsUpdate.emit(availableTeacherIds);
  }

  mapScheduleToCalendarEntry(schedule: SimpleScheduleEvents): CalendarEntry {
    const dateTo = new Date();
    const dateFrom = schedule.events[0].eventDate
    const duration = schedule.events[0].duration
    dateTo.setTime(dateFrom.getTime() + duration);
    const title = schedule.schedule.name;

    return new CalendarEntry(schedule.schedule.id, 1, dateFrom, dateTo, () => title, null, schedule, 'schedule');

  }

  mapAvailabilitiesToCalendarEntries(
    worktimes: SimpleTeacherProductAvailability[]
  ): CalendarEntry[] {
    const rangeSet = new DateRangeSet();

    for (const wt of worktimes) {
      for (const ev of wt.events) {
        // create range set which holds just the worktime range
        const wtRangeSet = new DateRangeSet();
        const wtDateRange = new DateRange();
        wtDateRange.dateFrom = new Date(ev.eventDate);
        wtDateRange.dateTo = new Date(wtDateRange.dateFrom.getTime() + ev.duration);
        wtRangeSet.add(wtDateRange);

        // create overtake range
        const overtakeRange = new DateRange();
        overtakeRange.dateFrom = new Date(0);
        if (wt.details.overtake == null) {
          wt.details.overtake = 0;
        }
        overtakeRange.dateTo = Dates.roundDateToNextHalfHour(new Date(new Date().getTime() + 60000 * 30 + wt.details.overtake));

        // subtract overtake and add results to the final range set
        wtRangeSet.sub(overtakeRange);
        wtRangeSet.orderedRanges.forEach(r => rangeSet.add(r));
      }
    }

    return rangeSet.orderedRanges.map(range => {
      return new CalendarEntry(null, 1, range.dateFrom, range.dateTo, null, null, range, 'worktime');
    })
  }

  private mapContextLessonTypeOnRequiredCompetences(): ApiCompetence[] {
    if (
      !this.studentProductContext ||
      !this.studentProductContext.nextLessonType
    )
      return null;

    const lessonTypeEnum =
      ApiLessonType[this.studentProductContext.nextLessonType];
    if (lessonTypeEnum === ApiLessonType.Standard) {
      return null;
    }
    if (lessonTypeEnum === ApiLessonType.Prova) {
      return [{ code: 'PROVA', name: null }];
    }
    return null;
  }

  onCalendarClick(event: ClickEvent) {
    if(!this.isCourseActive() && event?.entry?.dateFrom) {
      this._slScheduleDate = Dates.roundDateIntoMiddleOfClick(event.entry.dateFrom);
      this.activateCourseModalEvent.activateCourseModal.show()
      return;
    }
    if (event.entry != null && event.entry.relatedObject instanceof DateRange) {
      if (event.clickDate.getTime() < new Date().getTime() + 60000 * 30) {
        return;
      }
      this._slSelectedWorktimes = this.filterWorktimeByEventDate(
        event.clickDate
      );
      let teacherToSearch = Array.from(new Set(this._slSelectedWorktimes.map(wt => wt.teacher.id)))
      if (this.teachersIdFilter?.length > 0) {
        teacherToSearch = teacherToSearch.filter((teacherId) =>
          this.teachersIdFilter.includes(teacherId)
        );
        this._slShowTeachers = true;
        this._slShowAll = false;
        this._slPreferredTeacher = this.teachersIdFilter[0];
      }
      this._slTeacherIds = teacherToSearch;
      this._slTeacherProfiles = {};
      this.prepareTeachersProfiles(teacherToSearch)
        .pipe(
          tap((teachersProfiles) =>
            teachersProfiles.forEach(
              (teacherProfile) =>
                (this._slTeacherProfiles[teacherProfile.teacher.id] =
                  teacherProfile)
            )
          )
        )
        .subscribe((_) => this.openLessonScheduleDialog(event.clickDate));
    }
    if(event.entry.classes[0].includes("schedule")){
      const lessonId = event.entry.relatedObject.events[0].eventData.lesson.lessonId
      this.router.navigateByUrl(
        `student/col-lessons/school/1/student/${this._studentId}/lesson/${lessonId}`
      )
    }
  }

  prepareTeachersProfiles(teacherToSearch): Observable<ApiTeacherProfile[]> {
    return this.studentRest.getTeachers(this._studentId, teacherToSearch).pipe(
      tap((teachersProfiles) =>
        teachersProfiles
          .filter(
            (teacherProfile) =>
              !teacherProfile?.teacher?.person?.personalProfile
          )
          .forEach((teacherProfile) => {
            teacherProfile.teacher.person.personalProfile =
              new ApiPersonalProfile();
            teacherProfile.teacher.person.personalProfile.name =
              teacherProfile.teacher.person.name;
            teacherProfile.teacher.person.personalProfile.surname =
              teacherProfile.teacher.person.surname;
          })
      )
    );
  }

  filterWorktimeByEventDate(date: Date) {
    const asTime = Dates.roundDateIntoMiddleOfClick(date).getTime();
    const now = new Date().getTime();
    const lessonDuration = 30 * 60 * 1000
    return this.availableWorktimes
      .filter(wt => {
        let events = wt.events.filter(ev => asTime >= ev.eventDate.getTime() && (asTime + lessonDuration) <= (ev.eventDate.getTime() + ev.duration))
        return events.length > 0
      })
      .filter(wt => wt.details.overtake == null || asTime - now > wt.details.overtake );
  }

  openLessonScheduleDialog(date: Date) {
    if (this.credits === 0) {
      this.noCreditsModal.show();
      return;
    }
    this._slPreferredTeacher = null;
    this._slScheduleDate = Dates.roundDateIntoMiddleOfClick(date);
    this.scheduleDateEmitter.emit(this._slScheduleDate)
    this._slScheduleFinishDate = new Date();
    this._slScheduleFinishDate.setTime(
      this._slScheduleDate.getTime() + 1000 * 60 * 30
    );
    this._slShowTeachers = true;
    this._slShowAll = true;
    if (this.teachersIdFilter && this.teachersIdFilter.length > 0) {
      this._slShowAll = false;
      this._slPreferredTeacher = this._slTeacherIds[0];
    }
    // choose the teacher when is only one and not yet selected
    if (!this._slPreferredTeacher && this._slTeacherIds.length === 1) {
      this._slPreferredTeacher = this._slTeacherIds[0];
    }
    this.scheduleLessonModal.show();
  }

  getTeacherPhoto(teacherId: number) {
    const teacherProfile = this._slTeacherProfiles[teacherId];

    if (!teacherProfile || !teacherProfile.teacher) {
      return null;
    }
    return PersonUtils.getPersonProfilePhoto(teacherProfile.teacher.person);
  }
  getInitials(teacherId: number) {
    const teacherProfile = this._slTeacherProfiles[teacherId];

    if (!teacherProfile || !teacherProfile.teacher) {
      return null;
    }
    return PersonUtils.getInitials(teacherProfile.teacher.person);
  }

  getTeacherName(teacherId: number) {
    const teacherProfile = this._slTeacherProfiles[teacherId];
    if (!teacherProfile || !teacherProfile.teacher.person) {
      return '';
    }
    return PersonUtils.getPersonName(teacherProfile.teacher.person);
  }

  getTeacherDescription(teacherId: number) {
    const teacherProfile = this._slTeacherProfiles[teacherId];
    if (!teacherProfile) {
      return '';
    }
    const productCompetences = teacherProfile.competences.find(
      (c) => c.product.code === this._langCode
    );
    if (!productCompetences) {
      return '';
    }
    return productCompetences.productCompetenceDescription;
  }

  scheduleLesson() {
    this.loading = true;
    const lessonSchedule = this.prepareLessonSchedule();

    this.studentRest
      .reserveSchedule(this._studentId, lessonSchedule)
      .subscribe({
        next: () => {
          this.loadCalendarEvents();
          this.appEvents.credits(this._langCode).next(this.credits - 1)
        },
        error: (e) => {
          this.loading = false;
          this.handleReservationError(e);
        },
      });
    this.scheduleLessonModal.hide();
  }

  prepareLessonSchedule() {
    const lessonSchedule = new SimpleLessonScheduleRequest();
    const teacherAvailableCompetences = this._slSelectedWorktimes
      .map( wt => wt.details.competences)
      .reduce((acc, x) => acc.concat(x))
      .map((competence) => competence.code);
    const teachersUniqueCompetences = Array.from(
      new Set(teacherAvailableCompetences)
    );
    const scheduleCompetence = teachersUniqueCompetences.find(
      (competenceCode) => this.requiredCompetences.includes(competenceCode)
    );
    lessonSchedule.name = "";
    lessonSchedule.competence = new ApiCompetence();
    lessonSchedule.competence.code = scheduleCompetence;
    lessonSchedule.course = this.studentProductContext.currentCourse;
    lessonSchedule.course.product = new ApiCourseProduct();
    lessonSchedule.course.product.code = this._langCode;
    lessonSchedule.duration = 1000 * 60 * 30;
    lessonSchedule.time = new LocalDateTime();
    lessonSchedule.time.starting = this._slScheduleDate;
    lessonSchedule.time.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    lessonSchedule.teacher = new TeacherReference();
    lessonSchedule.teacher.id = this._slPreferredTeacher;

    return lessonSchedule;
  }

  handleReservationError(error: ErrorBase) {
    if (
      !error ||
      !error.errorCode ||
      error.errorCode !== 'INSUFFICIENT_RESOURCE'
    ) {
      return;
    }
    this.loadCalendarEvents();
    this._errorMessage = error.userMessage;
    this.reserveErrorModal.show();
  }

  isCourseActive() {
    return !!this.studentProductContext?.id
  }
}
